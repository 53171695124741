
.logo img {
  height: 100px;
  width: 100px;
  margin-bottom: 20px;
  border-radius: 50px;
  align-items: center;
  text-align: center;
}
.login-screen{
  width:100vw;
  height:100%;
  background-color: rgb(88, 64, 244);
}
.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}

.logo {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 4vh;
  text-align: center;
}

.head {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: calc(1.5em + 1.2vw);
  line-height: 20px;
  color: antiquewhite;
}

.text {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(1em + 0.4vw);
  font-weight: 200;
  color: antiquewhite;
  text-align: center;
}

.screenn {
  height:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 10vh;
}

.small-fluid {
  width: 100%;
  height: 2vw;
}

.login-card {

  width:35%;
  height: 45vh;
  background-color: #fcfcfc;
  border-radius: 2vw;
  box-shadow: 0px 0px 5px rgba(42, 41, 41, 0.63);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 0vh;
  margin-bottom: 6vh;
  
}

.form-input {
  margin-bottom: 20px;
  width: 90%;
  margin-right: 0;
  height: 5vh;
  border-radius: 5vw;
  margin-top: 1vw;
  margin-left: 0;
  
  
}

.labell {
  color: #5e6a75;
  font-style: normal;
  font-weight: normal;
  font-size: calc(.8em + 0.4vw);
  line-height: 20px;
  
}

.form-mainn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3vh;
  
  width:80%;
  height:90%;
}

.containerr {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  
}
 .btn {
  background-color: rgb(3, 145, 233);
  color: #fcfcfc;
  align-items: center;
  text-align: center;
  height:7vh;
}

.small-fluidd {
  height: 2vh;
}

@media screen and (max-width: 768px) {
  .login-card {
    width: 75%;
    border-radius: 5vw;
    text-align: center;
    align-items: center;
  }

  .form-input {
    align-items: center;
    width: 95%;
    height: 4vh;
   
    margin-bottom: 3vw;
    border-radius: 5vw;
    margin-top: 1vw;
  }

  .small-fluid {
    height: 4vw;
  }
}
.forgot-password {
  color: #484b49;
  cursor: pointer;
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
  /* text-decoration: underline; */
  transition: text-decoration 0.3s;

}


.forgot-password.underline {
  text-decoration: underline;
}
.success-message {
  color: #00E86B;
  text-align: center;
  font-size: 14px;
  margin-top: 10px;
}

.error-message {
  color: red;
  text-align: center;
  font-size: 14px;
  margin-top: 10px;
}
