@import url("https://fonts.googleapis.com/css2?family=Karla&family=Montserrat:wght@300;400;500;600;700;800;900&display=swap");

* {
  margin: 0px;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}
.feedback-main{
    margin-top: 10vh;
    width: 100%;
    height:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.feed-head{
    
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 5vh;
    border-bottom-right-radius: 5vh;
    background-color: rgb(255, 140, 58);
    color:white;
}
.feed-head h1{
    margin-top: 5%;
    font-size: calc(2em + 1vw);
    padding-bottom: .5vh;
}
.feed-head p{
    font-size: calc(.5em + 1vw);
    padding-bottom: 8%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.feed-form{
    width:60%;
    min-height: 300px;
    max-width: 700px;
    min-width: 200px;
    margin-top: 5%;
    margin-bottom: 5%;
    display: flex;
    flex-direction: column;
}
.Name{
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    margin-bottom: 4vh;

}
h2{
    padding:0;
    font-size: calc(.8em + .5vw);
}

#name,
#email{
    width:100%;
    height:3vh;
    min-width: 200px;
    min-height: 40px;
    margin-top: 1vh;
    font-size: calc(.6em + .4vw);
    float:none;
    margin-right: 0;
}

.Email{
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    margin-bottom: 4vh;

}.feed-type{
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    margin-bottom: 4vh;
    min-width: 200px;
    

}
.feed-cont{
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    margin-bottom: 4vh;

}
.content{
    margin-top: 2vh;
    width:100%;
    height:35vh;
    min-height: 250px;
    min-width: 200px;
}
.submit{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 2vh;
    
    

}
.submit button{
    margin-top: 5vh;
    width:30%;
    height:5vh;
    min-height: 40px;
    border-radius: 10px;
    border: none;
    font-size: calc(.5em + .8vw);
    background-color: rgb(245, 135, 57);
    color:white;
}
.custom-dropdown {
    width:100%;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
    cursor: pointer;
    margin-top: 1vh;

  }
  .feed-type select{
    float:none;
    margin-right:0;
    width:100%;
    height:5vh;
    min-height: 30px;
  }
  .custom-dropdown:focus {
    outline: none;
  }
  
  .custom-dropdown option {
    padding: 5px;
    
  }
  