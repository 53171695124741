@import url("https://fonts.googleapis.com/css2?family=Karla&family=Montserrat:wght@300;400;500;600;700;800;900&display=swap");

* {
  margin: 0px;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  max-width: 100%;
 
}

.mains-container{
    width:100vw;
    height: 100%;
    margin-top: 10vh;
    display:flex;
    flex-direction: column;
    align-items: center;

    
}
.main-heading{
    width:100%;
    height:30vh;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: rgb(254, 126, 87);
    padding-bottom:5vh;
    border-bottom-left-radius: 5vh;
    border-bottom-right-radius: 5vh;
    
}
.sticky {
    position: sticky;
    top: 10vh; /* Adjust as needed */
    z-index: 100;
  }
  
.main-heading h2{
    font-size: calc(2em + .8vw );
    text-transform: uppercase;
}
.main-heading p{
    font-size: calc(.8em + .4vw );
}
.mains-content{
    width:100%;
    margin-top: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.mains{
    width:70%;

    margin-bottom: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: rgb(230, 230, 229); */
}
.h2{
    width:87%;
    min-width: 670px;
    font-size: calc(1em + 1vw);
}
.slider-container{
    width:90%;
    min-width: 700px;
    height:30vh;
    display: flex;
    flex-direction: row;
    
    justify-content: center;

}
.cont{
    width:33%;
    height:80%;
    margin-left: 2%;
    margin-right: 2%;
    border-radius: 5px;
    background-color: rgb(253, 176, 152);
}