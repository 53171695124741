@import url("https://fonts.googleapis.com/css2?family=Karla&family=Montserrat:wght@300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

.navy {
  width: 100vw;
  height: 25vh;
  min-height: 200px;
  background-color: rgb(88, 64, 244);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin-top: 0;
  border-bottom-left-radius:5vh ;
  border-bottom-right-radius: 5vh;
  animation: slideFromTop 1s ease forwards;
}

@keyframes slideFromTop {
  from {
    margin-top: -35vh; /* Initial position above the viewport */
  }
  to {
    margin-top: 10vh; /* Final position with some margin from the top */
  }
}
.navy h1 {
  font-size: calc(2em + 1.8vw);
  font-weight: 600;
  letter-spacing: 1px;
  width:100%;
  /* display: flex; */
  padding: 0;
  /* flex-direction: row;
  text-align: center; */
  color: white;

}
.navy h2{
  color: rgb(224, 212, 212);
}

.head-heading{
  width:50%;
  margin-top: 10vh;
  font-weight: 700;
  text-align: center;
}
.head-heading p{
  font-size: calc(1.5em + 1.2vw);
}
.head-headings p{

  font-size: calc(.8em + .6vw);
}
.head-headings{
  width:80%;
  margin-top: 5vh;
  font-size: calc(1.3em + 1.4vw);
  font-weight: 700;
  text-align: center;
}
.contact{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.main-head{
  width: 100%;
  height:calc(20vh + 15vw);
  margin-top: 5vh;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.heads {
  width:33.34%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  margin-left: 1%;
  margin-right: 1%;

  
}

.head-pic{
  width:calc(9vw + 9vh);
  height:calc(9vh + 9vw);

}

.head-pic img{
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  width:100%;
  height:100%;
  border-radius: 50%;
  border-color: rgb(93, 93, 93);
  object-fit: cover; /* Ensures the image covers the area without distortion */

}
.abt-head{
  
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:100%;
  height:40%;
  font-size: calc(.6em + .5vw);
  
  
  
}
.abt-head p{
  font-size: calc(.6em + .5vw);
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.cityrp-main{
  margin-top: 0;
  width:100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgb(205, 220, 252);
}
.cityrp{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  
}
.zone {
  margin-top: 5vh;
  width:20%;
  display: flex;
  flex-direction: column;
  padding-left: 1%;
  padding-right: 1%;
}
.zone h2{

  font-size: calc( .6em + 1.5vw);
  font-weight: 700;
  margin-bottom: 5%;
}

.zone-name {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width:100%;
  margin-bottom: 5%;
  
}
.zone-name p{
  font-size: calc(.5em + .8vw);
  padding:0;
  padding-top: 1vh;
}
.cont-description{

  padding: 5px;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  margin-bottom: 3vh;
}
.cont-description h2 {
    margin-top: 0;
    font-weight: 500;
    font-size: calc(.8em + .7vw);
    letter-spacing: 2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.cityrep-login-btn {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: white;
  color: rgb(88, 64, 244);
  border: none;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cityrep-login-btn:hover {
  background-color: rgb(205, 220, 252);
  transform: scale(1.05);
}

@media only screen and (max-width: 768px) {
  
  .navy {
    width: 100vw;
    height: 25vh;
    background-color: rgb(88, 64, 244);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
    margin-top: 10vh;
    border-bottom-left-radius:5vh ;
    border-bottom-right-radius: 5vh;
  }
  .head-heading{
    margin-top:10%;
    width:70%;
    display: flex;
    align-items: center;
    justify-content: center;
   
  }
  .head-heading p{
    text-align: center;
    padding:0;
  }
  .head-headings{
    margin-top:1vh;
    margin-bottom: 2vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
.cityrp{
  display: flex;
  flex-direction: column;}
  .contact {
    width:100%;
    text-align: center;
  }
  .cont-description h2 {
    margin-top: 0;
    font-weight: 500;
    font-size: calc(.5em + .5vw + .5vh);
    letter-spacing: 2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.cityrep-login-btn {
  margin-top: 15px;
  padding: 8px 16px;
  font-size: 14px;
}

.zone{
  width:100%;
  margin-top: 0;
  align-items: center;
  text-align: center;
}
.zone-name{
  align-items: center;
  text-align: center;
  
}
.zone h2{
  margin-top: 5%;
  font-size: calc(1.1em + .5vw);
  border-bottom: 2px solid black;
}
.zone-name p{
  font-size: calc(.8em + .3vw);
}

}
