@import url("https://fonts.googleapis.com/css2?family=Karla&family=Montserrat:wght@300;400;500;600;700;800;900&display=swap");

* {
  margin: 0px;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  max-width: 100%;
 
}

.videos-main{
  margin-top: 10vh;
  width:100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.vid-rec{
  margin-top: 5%;
  width:80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.vid-rec p{
  margin-top: 1%;
  font-size: calc(1em + .5vw);
  font-weight: bold;
  color:rgb(102, 101, 101);
}
iframe{
  border-radius: 15px;
}
.vid-past{
  margin-top: 5%;
  width:90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.video{
  margin:3% 0% 3% 0%;
  width:100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.vid{
  margin:0 1% 0 1%;
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.vid p{
  margin-top: 1vh;
  font-size: calc(.7em + .4vw);
  color:rgb(102, 101, 101);
  font-weight: bold;
}
@media screen and (max-width: 768px) {
  .video {
    flex-direction: column;
  }
  .vid-rec{
    width:80%;
  }
  .iframe{
    width:90%
  }
}