/* Header.css */
@import url("https://fonts.googleapis.com/css2?family=Karla&family=Montserrat:wght@300;400;500;600;700;800;900&display=swap");
* {
  font-family: "Montserrat", sans-serif;
}
.header {
  width: 100%;
  height:10vh;
  min-height: 60px;
  
  position: fixed;
  top: 0;
  background-color: rgb(250, 250, 244);
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 999; /* Ensure it's above other content */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  font-size: calc(0.6em + .4vw);
}

.navbar{
  width:100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  
}
.techno-logo{
  /* width: 100px; */
  /* height:calc(8vh + 4vw); */
margin-top: 20px;
}

.hlink{
  
  width:30%;
  min-width: 160px;
  max-width: 250px;
  margin-left: 5%;
}
.hlink img{
  width:100%;
  min-width: 160px;
}
.hlink:hover{
  transform: scale(1.1);
 transition: transform 0.3s ease;;
}
.four-link{
  
  width:40%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: right;
  

}
.hlinks {
  width: 25%;
  color: rgb(154, 152, 152);
  align-items: center;
  text-align: center;
  text-decoration: none;
  
  position: relative; /* Ensure proper positioning of the bottom border */
}

.hlinks p{
  font-size: calc(.7em + .4vw);
  width:100%;
  font-weight: bold;
}
.hlinks:hover {
  color: rgb(75, 75, 239);
  
}
.regggg{
  width:30%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 5%;
}
.regist{
  background-color: rgb(75, 75, 239);
  color:white;
  text-decoration: none;
  width:50%;
  height: 5vh;
  min-height: 40px;
  max-height: 60px;
  min-width: 100px;
  max-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius:5px;
  font-weight: bold;
 

  
 
  
}
.hlinkss{
  
  width:50%;
  text-decoration: none;
  align-items: center;
  text-align: center;
  color:  rgb(154, 152, 152);
  padding: 5px;
  border-radius: 7px;
}
.hlinkss p{
  font-size: calc(.7em + .4vw);
  width:100%;
  font-weight: bold;
}
.hlinkss:hover {
  color: rgb(75, 75, 239);
  
}
.regist p{
  font-size: calc(.6em + .4vw);
}


.hamburger{
  display:none;
}
.handles-icons{
  display:none;
}
.techno{
  display: none;
}
@media (max-width: 768px) {
  .hamburger {
    width:100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  

  .hamburger-span{
    width:30%;
    display:flex;
    flex-direction: column;
    margin-left: 5%;
    z-index: 10000;
  }


  .hamburger-span-line {
    display: flex;
    flex-direction: column;
    width: calc(2vh + 5vw);
    height:7px;
    background-color:rgb(75, 75, 239);
    margin: 0.5vw;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
  }

  .techno{
    width:70%;
    display: block;
    display: flex;
    flex-direction: row;
    justify-content: end;
    /* padding-right: 5%; */

  }
  .techno img{
    height:calc(8vh + 4vw);

  }
  .navbar {
    display: none;
    
    }
  .navbar.open {
    display: flex;
    padding-top: 10vh;
    padding-right: 0;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    min-height: 400px;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    background-color: rgb(244, 247, 250);
    z-index: 1000;
    transition: all 0.5s ease; /* Smooth transition for opening/closing */
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.03), 0px 12px 12px 0px rgba(0, 0, 0, 0.03), 0px 27px 16px 0px rgba(0, 0, 0, 0.02), 0px 48px 19px 0px rgba(0, 0, 0, 0.00), 0px 75px 21px 0px rgba(0, 0, 0, 0.00);
  }
  .four-link{
    width:50%;
    display: flex;
    flex-direction: column;
    align-items: right;
    float:right;
    margin-left: 0;
    margin-right: 0;
    
  }
  
  .hlink{
    padding-bottom: 5vh;
    margin-left: 0;
    margin-right: 0;
    
    width:60%;
    display:flex ;
    justify-content: center;
    text-align: center;
    
    
  }
  .hlinkss{
    padding-bottom: 5vh;
    margin-left: 0;
    margin-right: 0;
   
    width:60%;
    display:flex ;
    justify-content: center;
    text-align: center;
    
    
  }
  .hlinkss p{
    width:100%;
    display:flex ;
    justify-content: center;
    text-align: center;
    font-size: calc(1em + 1vw);
  }
  .regggg{
    width:30%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding-left: 0%;
    margin-top: 10%;

  }
  .regist{
    margin-top: 0vh;
    width:30%;
    min-width: 100px;
    height:7vh;
    max-width: 150px;
  }
  .registp{
    font-size: calc(1em + 1vw);
    color: white;
  }
  .hlink img{
    height:calc(4em + 4vh);
    width:calc(15em + 12vw);
    min-width: 200px;
    
  }
  .hlinks{
    width:70%;
    padding-top:5vh;
    display:flex ;
    justify-content: center;
    text-align: center;
  
  }
  .hlinks p{
    width:100%;
    display:flex ;
    justify-content: center;
    text-align: center;
    font-size: calc(1em + 1vw);
  }
  .handles-icons{
    display: none;
    align-self: center;
    margin-top: 10rem;
    
    
}
.handles-icons a img{
  height:3vh;
  width:5vw;
  margin-left: 5vw;
}
}  
