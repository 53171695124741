/* Login.css */
.cityrep-login-container {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom right, #3b82f6, #6366f1);
  }
  
  .cityrep-login-box {
    background: white;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 24rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  
  .cityrep-login-header {
    text-align: center;
  }
  
  .cityrep-login-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 5rem;
    height: 5rem;
    background: #dbeafe;
    border-radius: 50%;
    margin: 0 auto;
  }
  
  .cityrep-login-title {
    margin-top: 1.5rem;
    font-size: 1.875rem;
    font-weight: bold;
    color: #1f2937;
  }
  
  .cityrep-login-subtitle {
    margin-top: 0.5rem;
    font-size: 0.875rem;
    color: #6b7280;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .input-group {
    display: flex;
    flex-direction: column;
  }
  
  .input-label {
    font-size: 0.875rem;
    font-weight: medium;
    color: #374151;
  }
  
  .input-field {
    margin-top: 0.25rem;
    padding: 0.5rem 0.75rem;
    border: 1px solid #d1d5db;
    border-radius: 0.5rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    font-size: 0.875rem;
    color: #4b5563;
    width: 100%;
  }
  
  .input-field:focus {
    outline: none;
    border-color: #3b82f6;
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.25);
  }
  
  .error-message {
    color: #dc2626;
    font-size: 0.875rem;
    text-align: center;
  }
  
  .submit-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.75rem;
    background: #3b82f6;
    color: white;
    font-size: 0.875rem;
    font-weight: medium;
    border-radius: 0.5rem;
    border: none;
    cursor: pointer;
    transition: background 0.2s;
  }
  
  .submit-button:hover {
    background: #2563eb;
  }
  
  .submit-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.25);
  }
  