@import url("https://fonts.googleapis.com/css2?family=Karla&family=Montserrat:wght@300;400;500;600;700;800;900&display=swap");
* {
  margin: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}
.kv-main{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.kv-main-head{
    width:100%;
    background-color: burlywood;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 5vh;
    border-bottom-right-radius: 5vh;

}
.kv-main-heading{
    margin-top: 6%;
    font-size: calc(1.5em + 3vw);
    padding: 0;
}
.kv-main-headings{
    font-size: calc(.5em + 1vw);
    margin-bottom: 5%;
}
.kv-content{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.kv-stud{
    margin-top: 5%;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.kv-student{
    font-size: calc(1em + 2vw);
    font-weight: 700;
    
}
.kv-principal{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
