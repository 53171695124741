@import url("https://fonts.googleapis.com/css2?family=Karla&family=Montserrat:wght@300;400;500;600;700;800;900&display=swap");

* {
  margin: 0px;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}
.user-main{
  width:100vw;
  display: flex;
  flex-direction: row;
  height:100vh;
}
.user-side{
  display: flex;
  flex-direction: column;
  height:105vh;
  color: black;
  background-color: #223438;
  transition: 0.5s;
}
.user-disp{
  display: flex;
  height: 100vh;
}
.blue-techno{
  width: 100%;
  height: 8%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.techno-img{
  width:100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.techno-img img{
  width:auto;
  height: 80%;
  border-radius: 1000px;
  margin: 10px 0px;
}
.page-cont{
  width:100%;
  height:100%;
  display: flex;
  flex-direction: column;
}
.nav{
  width:100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: rgba(255, 255, 255, 0.741);
  margin-bottom: 5%;
  font-weight: 600;
  font-size: calc(.7em + 1vh);
  background-color: #1c2a2dca;
}
.Profile,
.Updates,
.Results,
.Technopedia,
.SignOut {
  width: 100%;
  height:10%;
  display: flex;
  flex-direction: row;
  justify-content:left;
  align-items: center;
  color: white;
  margin: 5px 0px;
  font-size: calc(.7em + .8vw);
}
.active {
  border-left: 5px solid green;
  background-color: #152022ca;
  color:rgba(255, 255, 255, 0.588);
}

.navLi , .active{
  cursor: pointer;
}
.SignOut img , .navLi img{
  width:25px;
}
.navLi:hover{
  background-color: #333e41ca;
  border-left: 5px solid rgba(0, 128, 0, 0.685);
}
.hamburger-lines svg{
  width: 30px;
  cursor: pointer;
}
.hamburger-lines svg:hover{
  stroke: black;
}
.hamburger-lines{
  margin: 10px;
}

@media screen and (max-width:750px) {
  
  .nav{
    display: none;
  }

  .SignOut , .navLi{
    font-size: calc(.5em + .5vw);
  }
  .SignOut img , .navLi img{
    width:15px;
  }
}
@media screen and  (max-width:450px) {
  .user-side{
    width:30vw;
}
  .active {
    border-left: 2px solid green;
  }
  .navLi:hover{
    border-left: 2px solid rgba(0, 128, 0, 0.685);
  }
}