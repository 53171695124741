@import url("https://fonts.googleapis.com/css2?family=Karla&family=Montserrat:wght@300;400;500;600;700;800;900&display=swap");

* {
  margin: 0px;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}
.faq-main{
  margin-top:10vh;
  width:100%;
  height:100%;
}
.faq-head{
  width:100%;
  color:white;
  background-color: rgb(0, 132, 255);
  border-bottom-left-radius: 5vh;
  border-bottom-right-radius: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(2em + 2vw);
  font-weight:600;
  
}
.faq-head h1{
  margin-top: 5%;
  padding-bottom:1%;
  font-size: calc(.5em + 1.5vw);

}


.faqs-q{
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
  margin-bottom: 5%;
}
.faq-item{
  color:rgb(59, 59, 59);
  width: 75%;
  border-radius:5px ;
  border: solid #474646;
  margin-bottom: 3px;
}
.faq-question{
  width:100%;
  font-size: calc(.5em + 1vw);
  padding:4vh;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content:space-between;
  background-color: rgb(245, 245, 245);
  
 
}
.faq-answer{
  width:100%;
  font-size: calc(.5em + .8vw);
  padding:3vh;
  padding-top: 0;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  background-color:rgb(245, 245, 245);
}
.faq-head p{
  margin-bottom: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  
}
.faq-question img{
  height:3vh;
  width:3vh;
  float: right;
  margin-left: 5%;
}
.rotated {
  transform: rotate(180deg);
  transition-property: transform;
  transition-duration: 1s; 
}


