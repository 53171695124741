.footer {
  width: 100vw;
  min-height: 200px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(247, 247, 241);
  border-top-left-radius: 5vh;
  border-top-right-radius: 5vh;
}
.frow {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  padding-top: 3%;
  padding-bottom: 2%;
}
.frow:nth-child(2){
  margin: 0 5%;
  max-width: 80%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}
.frow-last {
  width: 100%;
  font-size: calc(0.45rem + 0.7vw);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
  color: #8f9392;
  padding-left: 15%;
  padding-right: 15%;
  
}
.frow-div{
  float: left;
  font-size: calc(.7em + .2vh + .2vw);
  font-weight: 500;
}
.columns {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  text-align: center;
  padding: 0 0.8rem;
  margin-left: 4%;

}
.columns_icons{
  display:flex;
  flex-direction: row;
  align-items: flex-end;
  float: right;
  width:25%;
}
.col-ic{
  width:calc(2vh + 2vw);
 height:calc(1.25vh + 1.25vw);
 margin-left: 2%;
 margin-right: 2%;
  
}
.col-ic img{
 width: 100%;
 height: 100%;
}
.flinks {
  width: 100%;
  padding: 0.35rem;
  text-decoration: none;
  color: #717171;
  font-weight: 600;
  word-wrap: normal;
  font-size: calc(0.5rem + 0.7vw);
}
.flinks:hover{
  color: #8f9392;
}
.Hr {
  width: 100vw;
}
/* .frow1 {
  max-width: 30%;
  min-width: 25%;
  display: flex;
  justify-content: space-around;
  align-items: left;
} */
.space{
  width: 5vw;
}

@media (max-width: 768px) {
  .footer{
    display:block;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .columns{
    margin:0;
    padding:.2vh;
  }
  .flinks{

    font-size: calc(.5em + .7vw);
  }
  .columns_icons {
    width:40%;
    height: 5vh;
    min-height: 20px;
    margin-top: 2vh;
    min-width: 150px;
    float:none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .columns_icons a img{
    height:100%;
    width:4vw;

  }
  .col-ic{
    height:calc(1.5vh + 1.5vw);
    width:calc(1.5vh + 1.5vw);
    padding: 0;
  }
  .frow{
    padding:0;
    padding-top: 4vh;
  }
  .frow-div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  hr{
    display:none;
  }
  .frow-last{
    height:13vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding:0;
    padding-top: 3vh;
    padding-bottom: 3vh;
  }
}