@import url("https://fonts.googleapis.com/css2?family=Karla&family=Montserrat:wght@300;400;500;600;700;800;900&display=swap");
* {
  margin: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}
.floating-notice {
  margin-top: 10vh;
  width: 100vw;
  height: 8vh;
  z-index: 800;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(228, 228, 46);

}
.floating-notice p{
  padding-top: 18px;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.footer {
  margin-top: auto;
}



.splash-screen {
    /* Add any common styles for the splash screen here */
    width: 100vw;

    max-height: 1000px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 8%;
    background-color: rgb(75, 75, 239);
  height:90vh;
  min-height: 800px;
  }



  .Heading-Hero {
    justify-content: center;
    width: 65%;
    color: #fff;
    font-size: calc(2em + 1vw);
    font-weight: 600;
    letter-spacing: 1px;
    padding-bottom: 3%;
    border-bottom: 3px solid #fff;
    opacity: 0;
    animation: slideFromTopHeading 1s ease forwards; 

  
  }
  
  .SubHeading-Hero {
    width: 80%;
    color: #fff;
    margin-top: 5vh;
    opacity: 0;
    animation: slideFromTopSubHeading 1s ease forwards; /* Animation to slide from top for subheading */
    display: flex;
    /* justify-content: center; */
  }
  
  @keyframes slideFromTopHeading {
    from {
      opacity: 0;
      transform: translateY(-100%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes slideFromTopSubHeading {
    from {
      opacity: 0;
      transform: translateY(-100%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
.divbig{
  height:100%;
  width:50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0vw;
  font-size:1.25vw;
}
.center{
  color:white;
  margin-top: 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
}
.check-center{
  
  font-size: calc(1vw + .5vh);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
}
.btn1{
  width:30%;
  height:calc(1.5vh + 1.5vw);
  border-radius: 5px;
  background-color: white;
  border: none;
  margin-top: 2vh;
  font-size: calc(.9vw + .5vh);
  color: #000;
  font-weight: 500;
}
/* .Hero-img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:50%;
  height:100%;
  margin-right: 5%;
  z-index: 0;
  top: 50%;
  right: 0; 
  opacity: 1; 
  animation: floatAnimation 2s ease infinite alternate; 
} */
.Hero-img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:50%;
  height:100%;
  margin-right: 5%;
  z-index: 0;
  top: 50%;
  right: 0; 
  opacity: 1; 
  animation: floatAnimation 2s ease infinite alternate; /* Animation to make it appear floating */
}



@keyframes floatAnimation {
  from {
    transform: translateY(0%) translateX(0); /* Start with no horizontal movement */
  }
  to {
    transform: translateY(5%) translateX(3%); /* Float a bit to the right */
  }
}

.image-containerrr {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.sub-link {
    width: 100%;
    max-width: 1000px;
    margin-top: 20px;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.parent {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin: 3vh 0;
    position: relative;
    min-height: 400px;
} 
.parent-1{
    width:100%;
    height:50vh;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    margin-top: 7vh;
    margin-bottom: 10vh;
    min-height: 300px;
}
.info-card{
  width:25%;
  min-width:230px;
  max-width: 420px;
  max-height: 650px;
  height:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 30px;
  margin: 3vw 2vw;
  border: 1px solid #ddd;
  border-radius: 5px;
}
.info-card:hover{
  transform: scale(1.1);
  transition: transform 0.3s ease;
}
.info-card img{
  width: 30%;
  height: 25%;
  margin-top: 2vh;
  display: flex;
  justify-content: center;
  align-items: center;
  
}


.info-card-heading {
  height:8%;
  width:100%;
  font-size: calc(.9em + .5vw);
  font-weight: bold;
  margin: 10px 0;
}
  
.info-card-content {
  height:35%;
  font-size: calc(.6em + .5vw);
  margin-bottom: 10px;
}
  
.info-card a {
  height:10%;
  text-decoration: none;
  color: #007bff;
  font-weight: bold;
  font-size: calc(1em + .5vw);
}
  
.info-card a:hover {
  text-decoration: underline;
}
.heading {
    margin-bottom: 20px;
    text-align: center;
}
.main-headss {
  
    font-weight: 600;
    font-size: calc(20px + 1vw);
    font-variant-caps: small-caps;
    background-color: white;
}
.image-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

}  
.prize-img {
    height: 45vh;
    min-height: 300px;
    max-height: 450px;
    width: 25vw;
    min-width: 200px;
    max-width: 450px;
    margin-left: 2vw;
    margin-right: 2vw;
    border-radius: 10px;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.5);

}

.prize-img:hover{
  transform: scale(1.1);
 transition: transform 0.3s ease;
}


.prize-imgg {
  height: 45vh;
  min-height: 300px;
  max-height: 400px;
  width: 20vw;
  min-width: 250px;
  max-width: 450px;
  margin-left: 2vw;
  margin-right: 2vw;
  border-radius: 10px;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.5);

}

.prize-imgg:hover{
transform: scale(1.1);
transition: transform 0.3s ease;
}
.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.separator {
    height: 1px;
    background-color: #ffffff;
    margin: 10px 0;
  }
  
  .parent-slider {
    padding-top: 30px;
    margin-left: 2vw;
    margin-right: 2vw;
    padding-left: 4%;
    padding-right: 4%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .h4-updates {
    font-weight: 500;
    font-size: calc(.8em + 1.5vw);
    margin-left: 0%;
    margin-bottom: 20px;
  }
  
  .slick-update {
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: space-between;
  }
  
  .update-card {
    background-color: rgb(252, 252, 252);
    border-radius: 12px;
    margin-left: 2vw;
    margin-right: 3vw;
    text-decoration: none;
    color: #000000;
    height:13vh;
    min-height: 100px;
    width: 25vw;
    max-width: 400px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
  }
  
  .update-card:hover {
    background-color: #f0f0f0;
   transition: transform 0.3s ease;
  }
  
  .update-card-icon {
    width:30%;
    height:100%;
    text-align: center;
  }
  .update-card-icon img{
    height:100%;
    width:100%;
    bottom: 0;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  
  .update-card-content {
    width:70%;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: calc(.5em + .7vw);
  }
  
  .sub-heading {
    text-align: center;
    width: 75%;
    font-size: 18px;
    font-weight: 500;
  }
  .parent hr{
    color: #28a745;
  }
  .line-container {
    margin-top: 20px;
    width: 100%;
    height: 8px;
    display: flex;
    align-items: center;  
    justify-content: center;
}
  
  .blue-line {
    width: 20%;
    height: 100%;
    background-color: rgb(58, 109, 247);
  }
  


  

  
  .one {
    /* Add styles for class "one" */
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .two {
    /* Add styles for class "two" */
    margin-top: 0.5%;
  }
  
  .three {
    /* Add styles for class "three" */
    margin-top: 20px;
  }
  
  .four {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .sub-link {
    width: 100%;
    max-width: 1000px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .link-text {
    /* Add styles for class "link-text" */
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .button {
    /* Add styles for buttons */
    padding: 10px 20px;
    margin-bottom: 10px;
    text-decoration: none;
    text-align: center;
    color: #fff;
    cursor: pointer;
  }
  
  .button-success {
    /* Add styles for success buttons */
    background-color: #28a745;
    border: none;
    border-radius: 5px;
  }
  
  .border-button {
    /* Add styles for border buttons */
    border: 2px solid #28a745;
    border-radius: 5px;
  }
  
  
@media only screen and (max-width: 1024px) {
  .info-card{
    width:50%;
    height:100%;
    max-width: 300px;
    display: flex;
flex-direction: column;
align-items: center; /* Align content horizontally at the center */
text-align: center;
margin:0 0 0 0;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  }




  
  @media only screen and (max-width: 768px) {
  
    .floating-notice {
      /* width:max-content; */
      height: 8vh;
      z-index: 800;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: rgb(228, 228, 46);
    }
    .btn1{
      height: calc(3vh + 2vw);
      width:20%;
      font-weight:600;
      font-size: calc(.5em + .5vh + .5vw) ;
      color: rgb(75, 75, 239);
    }
    
    .floating-notice p{
      padding-top: 18px;
    }
    
    .center{
      width:100%;
      display: flex;
      flex-direction: column;
      align-items: left;
      margin-top: 5%;
      margin-right: 7%;
     
    }
    .check-center{
      font-weight: 600;
      font-size: calc(1em + .7vh + .9vw);
    }
    .splash-screen {
        /* Add any common styles for the splash screen here */
        width: max-content;
        /* padding-left: 10%; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: rgb(75, 75, 239);
      }
    .Heading-Hero{
        /* width: 350px; */
        /* margin-top: 10%; */
        width:100%;
        margin-right: 7%;
        color: #fff;
        font-size: calc(2.5vh + 2.5vw);
        font-weight: 600;
        letter-spacing: 1px;
        justify-content: center;
        align-items: center;
        margin-bottom:3%;
        
    }
    .SubHeading-Hero{
        font-size: calc(1.25vh + 1.25vw);
        width: 100%;
        margin-right:7%;
        color: #fff;
        margin-bottom: 10%;
        /* margin-top: 36px; */
    }
    .Hero-img{
        z-index: 0;
        display: none;
        flex-direction: column;
        align-items: flex-end;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .sub-link {
        width: 100%;
        max-width: 1000px;
        margin-top: 20px;
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .parent {
      width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
        margin: 3vh 0;
        
        
    } 
    .parent-1{
        row-gap: 5vh;
        width: 100vw;
        height:fit-content;
        display: flex;
        align-items: center;
        flex-direction:column;
        text-align: center;
        margin-top: 10%;
    }
    .info-card{
      width:50%;
      height:90%;
      max-width: 300px;
      display: flex;
  flex-direction: column;
  align-items: center; /* Align content horizontally at the center */
  text-align: center;
  margin:0 0 0 0;
      border: 1px solid #ddd;
      border-radius: 5px;
  
    }
    .info-card img{
      width: 90%;
      height: 45%;
      
      
    }
    .info-card-heading {
      height:10%;
      width:80%;
      font-size: calc(1em + .5vw);
      font-weight: bold;
      margin-top: 5%;
      text-align: center;
      
      
    }
      
    .info-card-content {
      display: none;
      
      margin-bottom: 10px;
      
    }
      

    .info-card a {

      height:10%;
      width:85%;
      font-size: calc(1em + .5vw);
      text-decoration: none;
      color: #007bff;
      font-weight: bold;
    }
      
    .info-card a:hover {
      text-decoration: underline;
    }
    .heading {
        margin-bottom: 20px;
        
    }
    .main-head{
      width: 100%;
        font-weight: 600;
        font-size: calc(1em + .6vw);
        font-variant-caps: small-caps;
        margin-bottom: 5%;
        margin-top: 5%;
    }
    .divbig{
      display: flex;
      flex-direction: column;
      column-gap: 10vh;
      width: 100%;
      background-color: rgb(75, 75, 239);
      justify-content: center;
      align-items: center;
    }
    .image-container {
      row-gap: 5vh;
      flex-direction: column;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width:60%;
        min-width: 230px;
        max-width: 300px;
        margin-right: 0vw;
        
    }  
    
    .prize-img {
        height: 100%;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        
    }
    .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .separator {
        height: 1px;
        background-color: #ffffff;
        margin: 10px 0;
      }
      
      .parent-slider {
        padding-top: 30px;
        margin-left: 0%;
        display: flex;
        align-items: center;
        flex-direction: column;
      }
      
      .h4-updates {
        font-weight: 500;
        font-size: calc(1em + 1vw);
        margin-left: 0%;
        margin-bottom: 5vh;
      }
      
      .slick-update {
       column-gap: 10vh;
        display: flex;
        flex-direction: column;
        align-self: center;
        width:100vw;
      }
      .update-card {
        background-color: rgb(252, 252, 252);
        border-radius: 12px;
        margin-left: 0px;
        margin-right: 0px;
        text-decoration: none;
        color: #000000;
        height: 16vh;
        min-height: 100px;
        margin-top: 2vh;
        margin-bottom: 2vh;
        display: flex;
        width: 60vw;
        align-self: center;
        max-width: 350px;
      }
      
      .update-card:hover {
        background-color: #f5f5f5;
      }
      .update-card-icon{
        height:100%;
        width:30%;
      }
      .update-card-icon img{
        height:100%;
        width:100%;
        border-top-left-radius:12px;
        border-bottom-left-radius: 12px;
      }
      
      .update-card-content {
        width:70%;
        height:100%;
        font-size: calc( .6em + 1vw);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
      .sub-heading {
        font-size: 5vh;
        text-align: center;
        max-width: 10%;
        
        font-weight: 500;
      }
      .parent hr{
        color: #28a745;
      }
      .line-container {
        margin-top: 20px;
        width: 100%;
        height: 8px;
        display: flex;
        align-items: center;  
        justify-content: center;
    }
      
      .blue-line {
        width: 20%;
        height: 100%;
        background-color: rgb(58, 109, 247);
      }
      
    
    .about-us-image {
        width: 70%; /* Set the maximum width to 75% of the parent container */
        height: 50%;
      }
      
      
    
      
      .one {
        /* Add styles for class "one" */
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 10px;
      }
      
      .two {
        /* Add styles for class "two" */
        margin-top: 0.5%;
      }
      
      .three {
        /* Add styles for class "three" */
        margin-top: 20px;
      }
      
      .four {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      
      .sub-link {
        width: 100%;
        max-width: 1000px;
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 5vh;
      }
      
      .link-text {
        /* Add styles for class "link-text" */
        /* width: 50%; */
        font-size: 6vh;
        font-weight: bold;
        margin-bottom: 10px;
      }
      
      .button {
        /* Add styles for buttons */
        height: 5vh;
        width: 5vh;
        padding: 10px 20px;
        margin-bottom: 10px;
        text-decoration: none;
        text-align: center;
        color: #fff;
        cursor: pointer;
      }
      
      .button-success {
        /* Add styles for success buttons */
        background-color: #28a745;
        border: none;
        border-radius: 5px;
      }
      
      .border-button {
        /* Add styles for border buttons */
        border: 2px solid #28a745;
        border-radius: 5px;
      }
      .main-headss{
        font-size: calc(20px + 1vw);
      }
    }
    
.statsclass{
  background-color: rgb(75, 75, 239);
  padding: 10px 80px;
  justify-content: space-evenly;
}

.events{
  margin-top: 10px;
  margin-bottom: 100px;
}
.event-heading{

  margin-top: 80px;
font-size: 3em;
font-weight: bold;
color: #333;
/* margin-bottom: 10px; */
/* text-transform: uppercase; */
font-variant-caps: small-caps;

}
@media only screen and (max-width: 480px) {
  .image-container {
    row-gap: 5vh;
    flex-direction: column;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width:60%;
      min-width: 230px;
      max-width: 300px;
      margin-right: 0vw;
      
  }  
  .prize-imgg {
    height: 45vh;
    min-height: 300px;
    max-height: 400px;
    width: 20vw;
    min-width: 230px;
    max-width: 450px;
    /* margin-left: 2vw;
    margin-right: 2vw; */
    border-radius: 10px;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.5);
  
  }
}
