/* Loader.css */
/* $color-background: #eaecfa;
$color-loader: #ce4233; */

/* Container to center the loader */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7); /* Slight background overlay */
  z-index: 9999; /* Ensures the loader appears on top of other content */
}

/* The loader animation */
.loader {
  width: 250px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-family: Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 900;
  color: #fba00c;
  letter-spacing: 0.2em;
  position: relative;
}

.loader::before,
.loader::after {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  background: #fba00c;
  position: absolute;
  animation: load 0.7s infinite alternate ease-in-out;
}

.loader::before {
  top: 0;
}

.loader::after {
  bottom: 0;
}

@keyframes load {
  0% {
    left: 0;
    height: 30px;
    width: 15px;
  }
  50% {
    height: 8px;
    width: 40px;
  }
  100% {
    left: 235px;
    height: 30px;
    width: 15px;
  }
}
