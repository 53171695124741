@import url("https://fonts.googleapis.com/css2?family=Karla&family=Montserrat:wght@300;400;500;600;700;800;900&display=swap");

* {
  margin: 0px;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

.green {
  width: 100vw;
  min-height: 200px;
  background-color: #25cb72;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin-top: 8vh;
  border-bottom-left-radius: 5vh;
  border-bottom-right-radius: 5vh;
  animation: slideFromTop 1s ease forwards;
}
@keyframes slideFromTop {
  from {
    margin-top: -35vh; /* Initial position above the viewport */
  }
  to {
    margin-top: 10vh; /* Final position with some margin from the top */
  }}
.green h1 {
  margin-top: 5vh;
  font-size: calc(2em + 1.8vw);
  font-weight: 600;
  letter-spacing: 1px;

}
.green p {
  text-align: center;
  font-size: calc(0.8em + .4vw);
  margin-bottom: 5vh;
}
.con {
  width: 100%;
  margin-top: 10vh;
  display: flex;
  align-items: flex-end;
  padding: 5px;
  padding-bottom: 25px;
  justify-content: center;
}
.con h3 {
  font-weight: 600;
  font-size: calc(1.5em + 0.75vw);
  letter-spacing: 2px;
  text-align: center;
}
.card {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100px;
}
.entry {
  width: 75%;
  display: flex;
  flex-direction: column;
  border: 2px solid #dadad8;
  border-radius: 5px;
  min-height: 100px;
}
.trow {
  width: 100%;
  height: 5vh;
  padding: 5px 20px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: calc(0.6em + 1vw);
}
.trow div {
  width: 33%;
}
.show {
  max-width: 100%;
  min-height: 90px;
  display: flex;
  align-items: center;
  padding: 0px 15px;
  justify-content: space-between;
}
.show button {
  text-decoration: none;
  outline: 0px;
  border: 0px;
  background-color: #ffffff;
}
.show h4 {
  font-size: calc(0.7em + 1vw);
  font-weight: 600;
}
#lft {
  text-align: left;
}
#ctr {
  text-align: center;
}
#rht {
  text-align: right;
}
.gap {
  height: 10vh;
}
.sp {
  height: 2px;
}
.toggle {
  overflow: hidden;
}

@media screen and (max-width: 480px) {
  .green h1{
   
    font-size: 3vh;
  }
}