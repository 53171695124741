/* File Upload Styles */
.cityrep-file-upload-container {
  border: 2px dashed #d1d5db;
  border-radius: 0.5rem;
  padding: 2rem;
  text-align: center;
  transition: border-color 0.3s;
}

.cityrep-file-upload-container:hover {
  border-color: #3b82f6;
}

.cityrep-file-upload-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.cityrep-file-upload-icon {
  width: 3rem;
  height: 3rem;
  color: #9ca3af;
}

.cityrep-file-upload-text {
  color: #6b7280;
}

.cityrep-file-upload-title {
  font-weight: 500;
}

.cityrep-file-upload-subtitle {
  font-size: 0.875rem;
  color: #9ca3af;
}

.cityrep-file-upload-input {
  display: none;
}

.cityrep-file-upload-button {
  padding: 0.5rem 1rem;
  background-color: #2563eb;
  color: white;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.cityrep-file-upload-button:hover {
  background-color: #1d4ed8;
}

