body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}
.wrapper {
  
   background-color: #fdf6e3; 
   display: flex;
   flex-direction: column;
   min-height: 100vh;

}



.containe {
  width: 70%;
  margin: 150px auto;
  
  background-color:#fff;
  border: 1px solid orange;
  padding: 20px;

  /* border-radius: 8px; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  
}

.heade {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.institute-name {
  font-size: 24px;
  color: #333;
  
}

.institute-info {
  font-size: 14px;
  color: #555;
}

.institute-logo {
  width: 100px;
}
.reg {
  width: 200px;
}

.form-title {
  text-align: center;
  font-size: 2em;
  margin-bottom: 20px;
  font-weight: bolder;
}

.section-title {
  background-color: #ffb347;
  padding: 10px;
  border-radius: 5px;

  margin-bottom: 15px;
  color:black;
  /* font-weight: bold; */
}

label {
  display: block;
  margin-top: 10px;
  font-weight: bold;
}

input, select, textarea {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f7f5f5;
  color: black;
}

.gender {
  display: flex;
  gap: 10px;
  align-items: center;
}

.terms {
  display: flex;
  align-items: center;
  gap: 10px;
}

.form-submit {
  background-color: #ffb347;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.form-submit:hover {
  background-color: #ffa533;
}
.back{
  background-color: #707070;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}
@media screen and (max-width: 480px) {
  .containe {
    width: 95%;
    margin: 100px auto;
    
    background-color:#fff;
    border: 1px solid orange;
    padding: 20px;
  
    /* border-radius: 8px; */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    
  }
  .form-title {
    text-align: center;
    font-size: 1.5em;
    margin-bottom: 20px;
    font-weight: bolder;
  }
  
  }