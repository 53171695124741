                                                                                                                                                                                         .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
}
.modal-content h3{
margin-bottom:5px;
    text-align:left;
    border-radius:2px;
    margin-top:0;
    background-color: blue;
    color:white;
 
  
}

.modal-actions {
    margin-top: 30px;
}

.modal-btn {
    margin: 0 10px;
    padding: 10px 20px;
    cursor: pointer;

}
.modal-btn:hover{
    background:blue;
    color:white;
}