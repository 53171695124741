/* Base styles */
.instruction-btn {
  /* background-color: #007bff; */
 background-color: #707070;
  

  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bolder;
}

.instruction-btn:hover {
  background-color: #0056b3;
}

/* Instruction panel styles */
.reg-instr {
  position:fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  max-width: 600px;
  z-index: 1000;
  display: none;
  overflow-y: auto; /* Enable scrolling */

}

@media (max-width: 768px) {
  .reg-instr {
      position: fixed;
      top: 60%; /* Keep it centered vertically */
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: white;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      padding: 20px;
      width: 90%;
      max-width: 600px;
      z-index: 1000;
      display: none;
      overflow-y: auto; /* Enable scrolling */
      max-height: 80vh; /* Limit height for mobile screens */
  }
}



.reg-instr.expanded {
  display: block;
}

.reg-instr h2 {
  margin: 0 0 10px;
  font-size: 24px;
  text-align: center;
}

.reg-instr ul {
  padding: 0;
  list-style-type: disc;
}

.reg-instr li {
  margin: 10px 0;
  font-size: 16px;
}

.reg-instr .disclaimer {
  margin-top: 15px;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: red;
  font-size: 2rem;
}
